import axios from "axios";
import CryptoJS from 'crypto-js';
import base64 from "crypto-js/enc-base64";
import store from "@/store";
import _ from 'lodash';
let cancelList = {};
const encrypt = (text) => {
  if (typeof (text) != "string") {
    text = JSON.stringify(text);
  }
  var key = base64.parse(process.env.VUE_APP_KEY); //为了避免补位，直接用16位的秘钥
  var iv = CryptoJS.enc.Latin1.parse(process.env.VUE_APP_IV); //16位初始向量
  var encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding
  });
  return btoa(JSON.stringify({
    iv: process.env.VUE_APP_IV,
    value: encrypted.toString(),
    mac: CryptoJS.SHA512(iv).toString()
  }));
};
const http = {
  "get"(path, data, config = {}, cancelname = null) {
    const isloadshow = !config.hide;
    const transForm = {
      transformRequest: [(data, headers) => {
        if (isloadshow) {
          // store.commit("publicData/startLoading");
        }
        return data;
      }],
      transformResponse: [(data) => {
        if (isloadshow) {
          // store.commit("publicData/endLoading");
        }
        let redata = data;
        if (typeof (data) == "string") {
          data = JSON.parse(data);
        }
        return data;
      }]
    }

    delete config.hide;
    config.SameSite = "Strict";
    if (config.headers) {
      config.headers['Authorization'] = (localStorage.Authorization) ? "Bearer " + (localStorage
        .Authorization) : "";
    } else {
      config.headers = {
        Authorization: (localStorage.Authorization) ? "Bearer " + (localStorage
          .Authorization) : ""
      }
    }
    if (cancelname && cancelList[cancelname]) {
      cancelList[cancelname]();
    }


    let tmp = [];
    data = (data || {});

    _.forEach(data, (item, keys) => {
      const val = typeof (item) == "string" || typeof (item) == "number" ? item : (item ?
        item.join(
          ",") : "");
      tmp.push(keys + "=" + val);
    });
    const CancelToken = axios.CancelToken;
    let cancel;
    const result = axios({
      method: 'get',
      url: path + (tmp.length > 0 ? "?" + tmp.join(
        "&") : ""),
      baseURL: process.env.VUE_APP_PATH + "/",
      data: data,
      responseType: 'json',
      cancelToken: new CancelToken(c => { //強行中斷請求要用到的
        (cancelname) ? cancelList[cancelname] = c: "";
      }),
      ...transForm,
      ...config
    });
    result.catch((thrown) => {

      // console.log(thrown.response);
    })
    return result;
  },
  "post"(path, data, config = {}, cancelname = null) {
    const isloadshow = !config.hide;
    const transForm = {
      transformRequest: [(data, headers) => {
        if (isloadshow) {

        }
        if (config.headers && config.headers['Content-Type'] == "multipart/form-data") {
          return data;
        }
        // return data;
        return JSON.stringify(data);
      }],
      transformResponse: [(data) => {
        if (isloadshow) {}
        if (typeof (data) == "string") {
          data = JSON.parse(data);
        }
        return data;
      }]
    }
    delete config.show;
    config.SameSite = "Strict";
    if (config.headers) {
      config.headers['Authorization'] = (localStorage.Authorization) ? "Bearer " + (localStorage
        .Authorization) : "";
    } else {
      config.headers = {
        Authorization: (localStorage.Authorization) ? "Bearer " + (localStorage
          .Authorization) : ""
      }
    }
    if (cancelname && cancelList[cancelname]) {
      cancelList[cancelname]();
    }
    const CancelToken = axios.CancelToken;

    // const result = axios.post(path,data,{
    //   ...config,
    //   baseURL: process.env.VUE_APP_PATH + "/",
    //   cancelToken: new CancelToken(c => { //強行中斷請求要用到的
    //     (cancelname) ? cancelList[cancelname] = c: "";
    //   }),
    //   ...transForm
    // });
    const result = axios({
      method: 'post',
      url: path,
      baseURL: process.env.VUE_APP_PATH + "/",
      data: data,
      responseType: 'json',
      cancelToken: new CancelToken(c => { //強行中斷請求要用到的
        (cancelname) ? cancelList[cancelname] = c: "";
      }),
      ...transForm,
      ...config
    });

    result.catch((thrown) => {
      if (thrown.response) {

      }
    })
    return result;
  }
}




export default {
  ...http,
  encrypt: encrypt,
}
