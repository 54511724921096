import { createApp } from 'vue'
import App from '@/App.vue'

import {
  library
} from '@fortawesome/fontawesome-svg-core'
import {
  fas
} from '@fortawesome/free-solid-svg-icons'

import {
  far
} from '@fortawesome/free-regular-svg-icons'
import {
  fab
} from '@fortawesome/free-brands-svg-icons'
import '@/style/webfontawesome.scss';

library.add(fas);
library.add(far);
library.add(fab);
// export default FontAwesomeIcon
// export default FontAwesomeLayers
// export default FontAwesomeLayersText
// const app = createApp(App);
// app.component('font-awesome-icon', FontAwesomeIcon)
// app.component('font-awesome-layers', FontAwesomeLayers)
// app.component('font-awesome-layers-text', FontAwesomeLayersText)
// app.mount("#app");
