import doc from "@/service/doc";
import _ from 'lodash';
let state = {
  DocList: {
    Group: [],
    List: [],
    TopList: [],
    Page: {},
    Tags: [],
    datacount: 0
  },
  DocInfo: {
    Group: {},
    Docinfo: {},
    DocTag: []
  }
};
const getters = {}
// actions
const actions = {
  getDocGroup({
    commit
  }) {
    const result = doc.getDocGroup();
    result.then((data) => {

      commit("setDocsGroup", data.data.result.menu);
      commit("setDocsTags", data.data.result.tags);
    });
  },
  getDoclist({
    commit
  }, data) {
    const result = doc.getDoclist(data.group, data.page, data.query);
    result.then((data) => {

      commit("setDocsList", data.data.result);
    });
    return result;
  },
  getDocPage({
    commit
  }, data) {
    const result = doc.getDocPage(data.method, data.id);
    result.then((data) => {
      commit("Public/setHeaderinfo", {
        title: data.data.result.Docinfo.name,
        meta: {
          keywords: data.data.result.Docinfo.KeyWords,
          description: data.data.result.Docinfo.content_s,
        }
      }, {
        root: true
      });
      commit("setGroupInfo", data.data.result);
    });
    return result;
  }
}

const mutations = {
  setDocsGroup(state, data) {
    state.DocList.Group = data
  },
  setDocsTags(state, data) {
    state.DocList.Tags = data
  },

  setDocsList(state, data) {
    state.DocList.List = data.Docslist;
    state.DocList.TopList = data.Top;
    state.DocList.datacount = data.datacount;
  },
  setGroupInfo(state, data) {
    state.DocInfo = data;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
