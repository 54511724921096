import mem from "@/service/member";
import _ from "lodash";
let state = {
	loginafterclosePath: ["Paysuccess"],
	loginafterClosePage: ["Cart"],
	memberinfo: {
		add: "",
		birthday: "",
		cellphone: "",
		email: "",
		homephone: "",
		image: "",
		location: "",
		name: "",
		phone: "",
		post: "",
		postelcode: "",
		pwupdate: "",
		school: "",
		sex: "",
		signdate: "",
		skill: "",
		subnews: false,
		userid: "",
		work: "",
	},
	liff: {
		userId: "",
		isLiff: "",
	},
	orderlist: [],
	PaySource: [],
};
const getters = {};
// actions
const actions = {
	chpwd({ commit }, data) {
		const result = mem.chpwd(data);
		return result;
	},
	getjoindata({ commit }) {
		return mem.getjoindata();
	},
	checkemail({ commit }, data) {
		return mem.checkemail(data);
	},
	joinmember({ commit }, data) {
		return mem.joinmagee(data);
	},
	recheckmail({ commit }, data) {
		return mem.recheckmail(data.email);
	},
	getmemberinfo({ commit }) {
		const result = mem.getmemberinfo();
		result.then((data) => {
			commit("setmemberinfo", data.data);
		});
		return result;
	},
	updatemember({ commit }, data) {
		const result = mem.updatemember(data);
		result.then((data) => {
			commit("setmemberinfo", data.data.result.memberinfo);
		});
		return result;
	},
	getOrderPost({ commit }, data) {
		const result = mem.getOrderPost({
			searchtext: data.search,
		});
		result.then((data) => {
			commit("setOrderlist", data.data.result);
		});
		return result;
	},
	getPaySource({ commit }, data) {
		const result = mem.getPaySource();
		result.then((data) => {
			commit("setPaySource", data.data.result);
		});
		return result;
	},
	chremarkto({ dispatch }, data) {
		const result = mem.chremarkto(data.uid, data.newremark, data.newsource);
		result.then((data) => {
			// dispatch("getOrderPost");
			// commit("setPaySource",data.data.result);
		});
		return result;
	},
	getClassdata({}, data) {
		const result = mem.getClassdata();
		return result;
	},
	reportmessage({}, data) {
		const result = mem.reportmessage(data.type, data.data);
		return result;
	},
	forgetpwd({ commit }, data) {
		const result = mem.getresetaccount(data);
		result.then((data) => {});
		return result;
	},
	getRefunddata({}, data) {
		const result = mem.getRefunddata(data.uid);
		return result;
	},
	saveRefunddata({}, data) {
		const result = mem.saveRefunddata(data.data, data.uid);
		return result;
	},
  getVoucherList({commit},data){
    const result = mem.getVoucherList(data.search);
		return result;
  }
};

const mutations = {
	setmemberinfo(state, data) {
		_.each(data, (item, keys) => {
			state.memberinfo[keys] = item;
		});
	},
	setOrderlist(state, data) {
		state.orderlist = data;
	},
	setPaySource(state, data) {
		state.PaySource = data;
	},
	setLiff(state, data) {
		state.liff.userId = data.userId;
		state.liff.isLiff = data.isLiff;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
